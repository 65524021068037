// Import styles
import './sass/style.scss';

// Import polyfills
import './js/polyfills';
import './js/alpine';
import './js/scripts';
import './js/vue';
import { RunThemeExtensions } from 'wly-theme-extensions';
import $ from 'jquery';

RunThemeExtensions({
    tools: {
        scrollToAnchorOptions: {
            offset: (document.querySelector('header')?.clientHeight || 0) + 100,
            onScrollStart: (target) => {
                if (target instanceof HTMLElement && target.classList.contains('module--is-accordeon')) {
                    if (target.style.display === 'none') {
                        const $showMore = $(target.parentNode.querySelector('.accordeon__show-more'));

                        $showMore.nextAll('.module--is-accordeon').show();
                        $showMore.remove();
                    }

                    if (!target.classList.contains('active')) {
                        target.querySelector('.wly-accordeon__title')?.dispatchEvent(new CustomEvent('show'));
                    }
                }
            },
        },
    },
});
